export default [
    {
        type: 'select',
        label: '问题类型',
        prop: "typeId",
        opt: [
            {
                label:'功能异常',
                value: 1
            },
            {
                label:'产品建议',
                value: 2
            },
            {
                label:'投诉',
                value: 3
            }
        ],
    },

    {
        type: 'date',
        label: '提交时间开始',
        prop: 'CreatedAtStart',
        value: []
    },
    {
        type: 'date',
        label: '提交时间结束',
        prop:'CreatedAtStart',
        value: []
    },
]
