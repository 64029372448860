<template>
  <div class="" style="padding: 15px; padding-top: 0">
    <TabHeader title="反馈历史"></TabHeader>
    <div style="height: 15px"></div>
    <SearchBar :formList="form1" @search="search" @reset="reset"></SearchBar>
    <a-table
      size="small"
      bordered
      :columns="columns"
      :data-source="tableData"
      style="margin-top: 20px"
      :scroll="{ x: 'max-content' }"
      :pagination="pagination"
      :customRow="customRow"
      :rowClassName="rowClassName"
    >
      <template slot="action" slot-scope="record">
        <a class="btn-a" type="link" @click="checkImgs(record)">查看截图</a>
      </template>
    </a-table>
    <ly-drawer title="查看截图" :visible="drawer.show" width="700px">
      <div class="imgs">
        <div class="img" v-for="(item, index) in imgs" :key="index">
          <img
            style="width: 190px; height: 190px"
            :src="item && item.includes('http') ? item : baseUrl + item"
          />
        </div>
      </div>
      <div slot="footer">
        <a-button-group>
          <a-button @click="drawer.show = false" type="primary">关闭</a-button>
        </a-button-group>
      </div>
    </ly-drawer>
  </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import form1 from "./curd/form1";
import { feedBackHistory } from "@/api/FeedBack";
import columns from "./curd/columns";
import TabHeader from "@/components/TabHeader";
export default {
  components: {
    SearchBar,
    TabHeader,
  },
  data() {
    return {
      selectIndex: null,
      columns: columns,
      form1,
      tableData: [],
      form: {},
      drawer: { show: false },
      imgs: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 20,
        showTotal: (total) => `共 ${total} 条`,
        showSizeChanger: true,
        showQuickJumper: true,
      },
      baseUrl: process.env.VUE_APP_CDN,
    };
  },
  methods: {
    // 点击的样式
    rowClassName(record, index) {
      return index === this.selectIndex ? "row-active" : "";
    },
    // 点击的效果
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.selectIndex = index;
            console.log(this.selectIndex);
          },
        },
      };
    },
    search(val) {
      this.form = Object.assign(this.form, val);
      this.getData();
    },
    reset() {
      Object.keys(this.form).forEach((ele) => {
        this.form[ele] = "";
      });
      this.getData();
    },
    getData() {
      feedBackHistory(this.form).then((res) => {
        this.tableData = res.data;
      });
    },
    checkImgs(val) {
      this.imgs = val.photos || [];
      this.drawer.show = true;
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .img {
    width: 30%;
  }
}
</style>
