<template>
  <div class="box">
    <div class="left">
      <a-form-model layout="inline" :model="formInline" :labelCol="{ span: 3 }">
        <a-form-model-item
          v-for="(item, index) in myformList"
          :label="item.label"
          :key="index"
        >
          <a-input
            v-model.trim="form[item.prop]"
            :placeholder="item.label"
            v-if="item.type == 'input'"
          >
          </a-input>
          <a-date-picker
            v-model="form[item.prop]"
            v-if="item.type == 'date'"
            valueFormat="YYYY-MM-DD"
          />
          <a-textarea
            v-if="item.type == 'textarea'"
            v-model.trim="form[item.prop]"
            :placeholder="item.label"
            @change="(val) => handlerTextarea(val, item.prop, form[item.prop])"
            allow-clear
          />
          <a-select
            v-model="form[item.prop]"
            v-if="item.type == 'select'"
            style="width: 150px"
          >
            <a-select-option
              v-for="(item2, index2) in item.opt"
              :key="index2"
              :value="item2.value"
            >
              {{ item2.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="right">
      <a-button-group>
        <a-button type="primary" @click="search"> 查询 </a-button>
        <a-button @click="reset"> 重置 </a-button>
      </a-button-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formList: {
      type: Array,
      default: () => {},
    },
  },
  watch: {
    formList: {
      handler(val) {
        this.myformList = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {},
      myformList: [],
      formInline: {
        user: "",
        password: "",
      },
      cform: {},
    };
  },
  methods: {
    search() {
      let obj = Object.assign({}, this.form);
      let cbj = {};
      console.log("aaaaa==>", this.form);
      console.log("b=bb=>", this.cform);
      for (let key in obj) {
        console.log("key", key);
        if (this.cform[key + "s"]) {
          cbj[key + "s"] = this.cform[key + "s"];
          break;
        } else {
          cbj[key] = obj[key];
        }
      }
      this.$emit("search", cbj);
    },
    reset() {
      Object.keys(this.form).forEach((ele) => {
        this.form[ele] = "";
      });
      this.cform = {};
      this.$emit("reset", this.form);
    },
    // Textarea
    handlerTextarea(row, prop, val) {
      let str = val.replace(/[^A-Za-z0-9-_]/g, ",");
      this.cform[prop + "s"] = str.split(",");
      for (let i = 0; i < this.cform[prop + "s"].length; i++) {
        if (this.cform[prop + "s"][i] == "") {
          this.cform[prop + "s"].splice(i, 1);
          i--;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;

  .left {
    width: 80%;
  }
}

/deep/.ant-form-item-label {
  width: 65px;
}
</style>
