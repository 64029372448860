/*
 * @Author: your name
 * @Date: 2021-12-01 15:54:41
 * @LastEditTime: 2021-12-06 13:56:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Waybill/index.js
 */

import httpService from "@/request"


export function feedBackGetTypes(params) {
    return httpService({
        url: `/user/feedBack/getTypes`,
        method: 'post',
        data: params,
    })
}
export function feedBackSumbit(params) {
    return httpService({
        url: `/user/feedBack/submit`,
        method: 'post',
        data: params,
    })
}
export function feedBackHistory(params) {
    return httpService({
        url: `/user/feedBack/history`,
        method: 'post',
        data: params,
    })
}
