/*
 * @Author: your name
 * @Date: 2021-11-29 17:02:07
 * @LastEditTime: 2021-12-30 12:02:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/Forecast/curd/index.js
 */
import moment from "moment";
export default [{
        title: '问题类型',
        dataIndex: 'type_name',
        key: 'type_name',
    },
    {
        title: '问题描述',
        dataIndex: 'content',
        key: 'content',
    },
    {
        title: '职位类型',
        dataIndex: 'position_type',
        key: 'position_type',
        customRender: (text) => {
            let arr=["","运营","仓库","财务","物流负责人",'其他']
            if(text){
                return arr[text];

            }
            return ""
        }
    },
    {
        title: '联系人 ',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '联系方式 ',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: '提交时间',
        dataIndex: 'created_at',
        key: 'created_at',
        customRender: (text) => {
            let str = ""
            if (text) {
                str = moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
            } else if (text === 2) {
                str = "-"
            }
            return str;
        }
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: {
            customRender: 'action'
        },
    },
]